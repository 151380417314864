.reports-container-wrapper {
  th {
    text-align: center !important;
    text-transform: uppercase !important;
    font-size: 12px !important;
    padding: 5px !important;
    font-weight: 600;
  }
  .name-column {
    min-width: 20rem !important;
    text-align: start !important;
  }
  td {
    text-align: center !important;
    text-transform: uppercase !important;
    font-size: 14px !important;
    color: rgb(34, 34, 34);
  }
  .total-wrapper {
    td {
      font-size: 14px !important;
      color: black !important;
      font-weight: bold;
      padding: 8px !important;
    }
    .text-field {
      text-align: center !important;
    }
  }
}

.table-loading-wrapper {
  padding: 0px !important;
}

.alert-styles {
  td {
    padding: 4px !important;
  }
  .alert {
    margin-top: 4px !important;
    font-size: 12px !important;
    text-transform: none;
  }
}

.report-class-style {
  font-size: 16px !important;
  color: black !important;
  font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
}
.report-ledger-style {
  color: black !important;
  font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
}
.report-table-heading {
  font-size: 16px !important;
  font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
  font-weight: 600 !important;
}
.report-total-title {
  font-weight: bold !important;
  color: black !important;
  font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
}

.simplebar-report-style {
  height: 77vh !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.simplebar-report-no-pagination-style {
  height: 82vh !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.simplebar-report-for-ledger-pagination-style {
  height: 84vh !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}
