.switches-container-custom-login {
  width: 16rem;
  position: relative;
  display: flex;
  padding: 0;
  position: relative;
  background: #6aabac;
  line-height: 3rem;
  border-radius: 3rem;
  margin-left: auto;
  margin-right: auto;
}

.switches-container-custom-login > .custom-login-switch {
  visibility: hidden;
  position: absolute;
  top: 0;
}

.switches-container-custom-login > .label-custom-login {
  width: 50%;
  padding: 0;
  margin: 0;
  text-align: center;
  cursor: pointer;
  color: white;
}

.switch-wrapper-custom-login {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50%;
  padding: 0.15rem;
  z-index: 3;
  transition: transform 0.3s cubic-bezier(0.77, 0, 0.175, 1);
}

.switch-custom-login {
  border-radius: 3rem;
  background: white;
  height: 100%;
}

.switch-custom-login > .slide-custom-login {
  width: 100%;
  text-align: center;
  opacity: 0;
  display: block;
  color: #6aabac;
  transition: opacity 0.3s cubic-bezier(0.77, 0, 0.175, 1) 0.125s;
  will-change: opacity;
  position: absolute;
  top: 0;
  left: 0;
}

.custom-login-switch:nth-of-type(1):checked ~ .switch-wrapper-custom-login {
  transform: translateX(0%);
}

.custom-login-switch:nth-of-type(2):checked ~ .switch-wrapper-custom-login {
  transform: translateX(100%);
}

.custom-login-switch:nth-of-type(1):checked
  ~ .switch-wrapper-custom-login
  .switch-custom-login
  > div:nth-of-type(1) {
  opacity: 1;
}

.custom-login-switch:nth-of-type(2):checked
  ~ .switch-wrapper-custom-login
  .switch-custom-login
  > div:nth-of-type(2) {
  opacity: 1;
}
