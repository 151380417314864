.customer-details-offcanvas{
    .customer-header{

        .profile-icon{
            width: 60px;
            height: 60px;
            background-color: #fff;
            border-radius: 50%;
        }
        background-color: #f3f8fe;
        margin: 0 -1rem;
        padding: 15px;
    }

    .customer-info-tab-content{
        h5{
            font-size: .95rem;
        }
        p{
            font-size: .8rem;
        }
    }

    .activity-tree{
        li{
            border-left: 1px solid #00d27a;
            .icon-cover{
                position: relative;
                left: -11px;
                background-color: #fff;
                width: 25px;
                height: 25px;
                border-radius: 50%;
                svg{
                    transform: scaleX(-1);
                    position: relative;
                    left: -1px;
                }
            }
            .card{
                margin-bottom: 1.5rem;
                .small{
                    font-size: .65rem;
                }
                p{
                    font-size: .75rem;
                }
            }

            &:last-child{
                .card{
                    margin-bottom: 0;
                }
            }
        }
    }
}