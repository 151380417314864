.ticket-custom-table-head-th {
  padding-top: 1.5vh !important;
  padding-bottom: 1.5vh !important;
}

.ticket-custom-table-body-td {
  padding-top: 1.5vh !important;
  padding-bottom: 1.5vh !important;
}

.ticket-custom-table-head-th-status {
  width: 10px !important;
}
.ticket-custom-table-body-td-status {
  width: 10px !important;
}

.ticket-custom-table tr td:first-child {
  padding-left: 0px !important;
}
.custom-clickable-link-crm {
  cursor: pointer;
}
.custom-link-style-crm {
  color: #000;
}
.custom-link-style-crm:hover {
  color: #000;
  text-decoration: none;
}
.ticket-custom-table-head {
  background-color: #e7f2fc;
}
