//Dropdwon styles
.css-1rb6wtk-control {
  border-radius: 0px !important;
  font-size: 12px !important;
  font-family: "Inter", "Source Sans Pro", Helvetica, Arial, sans-serif !important;
  min-height: 32px !important;
  height: 5px !important;
  border: 1px solid #b5b5b5 !important;
}
.css-1ti4bpj-control {
  border-radius: 0px !important;
  font-size: 12px !important;
  font-family: "Inter", "Source Sans Pro", Helvetica, Arial, sans-serif !important;
  min-height: 32px !important;
  height: 5px !important;
  border: 1px solid #b5b5b5 !important;
}
.css-13cymwt-control,
.css-16xfy0z-control {
  border-radius: 0px !important;
  font-size: 12px !important;
  font-family: "Inter", "Source Sans Pro", Helvetica, Arial, sans-serif !important;
  min-height: 32px !important;
  height: 5px !important;
  border: 1px solid #b5b5b5 !important;
}
.css-t3ipsp-control:hover {
  border-color: #b3a7ee !important;
}
.css-t3ipsp-control {
  border-color: #b5b5b5 !important;
  box-shadow: none !important;
  border-radius: 0px !important;
  font-size: 12px !important;
  font-family: "Inter", "Source Sans Pro", Helvetica, Arial, sans-serif !important;
  min-height: 32px !important;
  height: 5px !important;
}

.css-1xc3v61-indicatorContainer {
  padding: 3px !important;
}
.css-1hb7zxy-indicatorContainer {
  padding: 3px !important;
}
.css-15lsz6c-indicatorContainer {
  padding: 3px !important;
}

//Button styles
.list-add-button {
  padding: 2.8125px 6px !important;
}
.btn {
  font-family: "Inter", "Source Sans Pro", Helvetica, Arial, sans-serif !important;
}

//Random styles for Form
.form-heading {
  margin-bottom: 0 !important;
  display: flex !important;
  align-items: center;
  font-size: 18px !important;
  text-transform: uppercase;
}
form .form-label {
  font-size: 12px;
  font-weight: 500 !important;
  color: black;
  font-family: "Inter", "Source Sans Pro", Helvetica, Arial, sans-serif !important;
}
.simplebar-style {
  height: 79.8vh !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

//Alert styles
.alert {
  font-family: "Inter", "Source Sans Pro", Helvetica, Arial, sans-serif !important;
}

//Sidebar style
.navbar-vertical .navbar-nav .nav-item .nav-link {
  font-family: "Inter", "Source Sans Pro", Helvetica, Arial, sans-serif !important;
}
.settings-item .name {
  font-family: "Inter", "Source Sans Pro", Helvetica, Arial, sans-serif !important;
}
.notification {
  font-family: "Inter", "Source Sans Pro", Helvetica, Arial, sans-serif !important;
}

.heading-style {
  font-size: 18px !important;
  margin: 0px !important;
  text-transform: uppercase !important;
  display: flex;
  align-items: baseline;
}

//Sidebar style
.active-style {
  background-color: #686c96 !important;
}
.active-style-with-children {
  background-color: #333549 !important;
}
.hover-style:hover {
  color: whitesmoke !important;
  font-size: 14px;
  transition: all 250ms ease-out;
}

// Total component styles
.total-component-amount-style {
  font-size: 15px !important;
  color: black !important;
  font-weight: 500 !important;
}
.total-component-word-style {
  font-size: 14px !important;
  color: black !important;
  font-weight: 500 !important;
}

// Toast styles
.warnings {
  font-size: 11px !important;
  width: auto !important;
  min-width: 500px !important;
  .Toastify__progress-bar-theme--light {
    background: red !important;
  }
}
.success {
  font-size: 11px !important;
  width: auto !important;
  min-width: 500px !important;
  .Toastify__progress-bar-theme--light {
    background: rgb(0, 255, 85) !important;
  }
}

//Dropdown add button style
.select-item-dropdown-styles {
  padding-top: 2px !important;
  padding-bottom: 5px !important;
  padding-left: 2px !important;
  padding-right: 2px !important;
  .select-item-add-button {
    width: 100% !important;
    padding: 4px !important;
    border: 1px solid rgb(218, 215, 215);
    background-color: white !important;
    color: black !important;
    font-size: 11px !important;
    font-family: "Inter", "Source Sans Pro", Helvetica, Arial, sans-serif !important;
  }
}

//Modal Form heading style
.modal-form-heading {
  text-transform: uppercase !important;
  font-size: 17px !important;
  color: black !important;
  margin: 0px !important;
}

//For display resolution 1680 * 1050
@media screen and (min-width: 1600px) and (max-width: 1680px) {
  .simplebar-style {
    height: 79.2vh !important;
  }
}

//For display resolution 1440 * 886
@media screen and (min-width: 1440px) and (max-width: 1600px) {
  .simplebar-style {
    height: 78.5vh !important;
  }
}

//For display resolution 1024 * 886
@media screen and (min-width: 1024px) and (max-width: 1440px) {
  .simplebar-style {
    height: 78.5vh !important;
  }
}

//For display resolution 320 * 425
@media screen and (min-width: 320px) and (max-width: 425px) {
  .simplebar-style {
    height: 78.5vh !important;
  }
}

.import-modal-footer-alignment {
  // .modal-footer {
  justify-content: flex-start !important;
  // }
}
