.custom-field-wrapper {
  .nav {
    .nav-link {
      color: black !important;
      font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
    }
    .nav-link.active {
      background-color: rgb(236, 242, 248) !important;
      color: black !important;
      font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
    }
  }
  .custom-filed-simplebar-style {
    height: 69vh !important;
    overflow-x: hidden !important;
    overflow-y: auto !important;
  }
  .custom-field-label-style {
    display: flex;
    align-items: center !important;
    .form-label {
      margin: 0px !important;
      font-size: 12px !important;
    }
  }
  .creatable-select {
    .css-13cymwt-control {
      height: auto !important;
    }
    .css-t3ipsp-control {
      height: auto !important;
    }
    .css-wsp0cs-MultiValueGeneric {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }
  }
}
