.loader-animated > .loader-animated-element {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: inline-block;
  margin: 0.5rem;
  animation: loading 1.3s ease-in-out infinite;
}
@keyframes loading {
  0%,
  100% {
    transform: scale(0.2);
    background-color: #0ddcf8;
  }
  42% {
    background-color: #5d4da6;
  }
  50% {
    transform: scale(0.8);
    background-color: #42b58fb8;
  }
}
.loader-animated > .loader-animated-element:nth-child(0) {
  animation-delay: 0s;
}
.loader-animated > .loader-animated-element:nth-child(1) {
  animation-delay: 0.2s;
}
.loader-animated > .loader-animated-element:nth-child(2) {
  animation-delay: 0.4s;
}
.loader-animated > .loader-animated-element:nth-child(3) {
  animation-delay: 0.6s;
}
.loader-animated > .loader-animated-element:nth-child(4) {
  animation-delay: 0.8s;
}
.loader-animated-text {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}
