.ticket-total-span {
  color: #6071fd;
}
.ticket-larger-text {
  font-size: large;
}
.ticket-total-critical-content {
  color: #fd7d5d;
}
.custom-dashboard-list-group-item:last-of-type {
  border: none !important;
}
