.project-custom-thumbnail-container {
  position: relative;
}
.project-custom-profile-link-crm {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-left: 1.5vh;
}
.project-custom-profile-link-crm:first-child {
  top: 0 !important;
  left: 0 !important;
  z-index: 7 !important;
}
.project-custom-badge-crm {
  border-radius: 0rem 50rem 50rem 0rem !important;
  width: 9vh;
}

.project-custom-table-wrapper-crm {
  height: 75vh !important;
  overflow: auto;
}

.project-custom-header-text {
  padding-top: 2px !important;
}

.marquee-container {
  width: 100%;
  overflow: hidden;
  height: 23px !important;
  .marquee-content {
    display: inline-block;
    white-space: nowrap;
    animation: marquee-animation 8s linear infinite;
  }
}
@keyframes marquee-animation {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.project-grid-view-container:hover {
  .delete {
    color: red !important;
  }
  .view {
    color: black !important;
  }
  .edit {
    color: blue !important;
  }
}
.grid-icon {
  font-size: 17px !important;
  transition: transform 0.3s ease-in-out !important;
  box-shadow: none !important;
  border: none !important;
  color: whitesmoke !important;
}
.grid-icon:hover {
  transform: scale(1.4) !important;
}
