.crm-employee-nav {
  background-color: #e3e3e3;
}
.crm-employee-nav > .crm-employee-nav-item > .crm-employee-nav-link {
  color: #47484a;
}
.crm-employee-nav > .crm-employee-nav-item > .crm-employee-nav-link.active {
  background-color: #8491a3;
  color: #fff;
}
@media only screen and (min-width: 705px) {
  .crm-employee-nav > .crm-employee-nav-item > .crm-employee-nav-link.active {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4.9vh;
  }
  .crm-employee-nav > .crm-employee-nav-item > .crm-employee-nav-link {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4.9vh;
  }
}
@media only screen and (max-width: 499px) {
  .crm-employee-nav > .crm-employee-nav-item > .crm-employee-nav-link.active {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5.9vh;
  }
  .crm-employee-nav > .crm-employee-nav-item > .crm-employee-nav-link {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5.9vh;
  }
}
