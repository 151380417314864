@import "./userStyle/viewPage.scss";
@import "./userStyle/commonStyle.scss";
@import "./userStyle/settingsStyle.scss";
@import "./userStyle/voidStyles.scss";
@import "./userStyle/dashboardStyles.scss";
@import "./userStyle/reportStyle.scss";
@import "./userStyle/customerStyle.scss";
@import "./userStyle/appDatePickerStyles.scss";
@import "./userStyle/customFieldStyles.scss";
@import "./userStyle/userProfileStyle.scss";
@import "./userStyle/project.scss";
@import "./userStyle/leadsStyle.scss";
@import "./userStyle/digitalTimer.scss";
@import "./userStyle/DateRange.scss";

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.navbar-vertical .navbar-nav .nav-item .nav-link {
  color: white !important;
}

.login-page-input-style {
  color: white !important;
  input.form-control {
    background-color: transparent !important;
    border: none !important;
    border-bottom: 1px solid white !important;
    box-shadow: none;
    color: white !important;
  }
  input.form-control::placeholder {
    color: rgb(1, 160, 111) !important;
    background-color: transparent !important;
  }
  input.form-control:focus {
    box-shadow: none !important;
    border-color: white !important;
    background-color: transparent !important;
  }
  input.form-control:hover {
    border-color: white !important;
    background-color: transparent !important;
  }
}

.login-form-button {
  .btn {
    border-radius: 5px !important;
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    -ms-border-radius: 5px !important;
    -o-border-radius: 5px !important;
    color: rgb(1, 160, 111) !important;
    box-shadow: none !important;
    border: 2px solid white !important;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn:hover {
    background-color: rgb(1, 160, 111) !important;
    color: white !important;
  }
  .btn:focus {
    background-color: white !important;
    color: rgb(1, 160, 111) !important;
  }
}

.require-data::after {
  content: "*";
  color: #f15f5f;
}

.view-page-dropdown {
  padding: 3px 8px !important;
  box-shadow: none !important;
  border: none !important;
}

::-webkit-scrollbar {
  display: none;
}

.btn-close {
  box-shadow: none !important;
  border: none !important;
}

.select {
  background-color: rgb(212, 242, 255);
}

input.form-control {
  border-radius: 0px !important;
  border: 1px solid #b5b5b5;
  font-size: 12px;
  line-height: 20px !important;
  font-weight: 400 !important;
}
input.form-control:focus {
  box-shadow: none !important;
  border-color: #b3a7ee !important;
}
input.form-control:hover {
  border-color: #b3a7ee !important;
}
input.form-control::placeholder {
  font-size: 12px;
  color: white !important;
}

textarea {
  border-radius: 0px !important;
  border: 1px solid #b5b5b5 !important;
  font-size: 12px !important;
  font-weight: 400 !important;
}
textarea::placeholder {
  color: rgb(140, 152, 165) !important;
}
textarea:focus {
  box-shadow: none !important;
  border-color: #b3a7ee !important;
}
textarea:hover {
  border-color: #b3a7ee !important;
}

.css-1s2u09g-control {
  border-radius: 0px !important;
  font-size: 12px !important;
  font-family: "Inter", "Source Sans Pro", Helvetica, Arial, sans-serif !important;
  min-height: 32px !important;
  height: 5px !important;
  border: 1px solid #b5b5b5 !important;
}
.css-319lph-ValueContainer {
  font-size: 12px;
  font-family: "Inter", "Source Sans Pro", Helvetica, Arial, sans-serif !important;
  text-transform: capitalize !important;
}
.css-6j8wv5-Input {
  margin: 0px !important;
  padding: 0px !important;
}

.css-rb1vna-control {
  min-height: 32px !important;
  height: 5px !important;
}

.css-1705uev-control {
  min-height: 32px !important;
  height: 5px !important;
}

.css-tlfecz-indicatorContainer {
  padding: 3px !important;
}

.css-1gtu0rj-indicatorContainer {
  padding: 3px !important;
}

.css-1hb7zxy-indicatorContainer {
  padding: 3px !important;
}

.css-1pahdxg-control {
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -ms-border-radius: 0px !important;
  -o-border-radius: 0px !important;
  min-height: 32px !important;
  height: 5px !important;
  border: 1px solid #2688ff !important;
  box-shadow: none !important;
}

.css-1jknvbe-menu {
  font-size: 12px;
  font-family: "Inter", "Source Sans Pro", Helvetica, Arial, sans-serif !important;
  text-transform: capitalize !important;
}

.discount-conversion-button {
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -ms-border-radius: 0px !important;
  -o-border-radius: 0px !important;
  background: white !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  padding-right: 4px !important;
  padding-left: 4px !important;
  border: 1px solid #b5b5b5 !important;
  box-shadow: none !important;
  position: relative !important;
  z-index: 0 !important;
  .btn:focus {
    border: 1px solid #b5b5b5 !important;
  }
}

.form-select {
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -ms-border-radius: 0px !important;
  -o-border-radius: 0px !important;
  border: 1px solid #b5b5b5 !important;
  font-size: 12px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
}

.form-select:focus {
  box-shadow: none !important;
}
.form-select::placeholder {
  font-size: 10px !important;
}

.options {
  background-color: white !important;
  font-family: "Inter", "Source Sans Pro", Helvetica, Arial, sans-serif !important;
  font-size: 12px !important;
  border: 1px solid #b5b5b5 !important;
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -ms-border-radius: 0px !important;
  -o-border-radius: 0px !important;
  line-height: 25px !important;
}
.options:focus {
  border-color: #2688ff !important;
}
.options:hover {
  border-color: #2688ff !important;
}
.options::placeholder {
  color: rgb(140, 152, 165) !important;
}

td {
  vertical-align: middle !important;
}

.form-table-layout {
  thead {
    tr {
      th {
        font-weight: 600 !important;
        font-size: 12px !important;
      }
    }
  }
}

.table-label {
  font-family: "Inter", "Source Sans Pro", Helvetica, Arial, sans-serif !important;
  font-weight: 400 !important;
}

.table-header-size {
  font-size: 12px !important;
  padding: 8px !important;
  text-transform: uppercase !important;
}

button {
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -ms-border-radius: 0px !important;
  -o-border-radius: 0px !important;
}

.btn {
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -ms-border-radius: 0px !important;
  -o-border-radius: 0px !important;
}

.card {
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -ms-border-radius: 0px !important;
  -o-border-radius: 0px !important;
}

//for validation
.css-b62m3t-container {
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -ms-border-radius: 0px !important;
  -o-border-radius: 0px !important;
  border-color: #b5b5b5 !important;
}
.form-control.is-invalid {
  border-color: #b5b5b5 !important;
  .css-13cymwt-control {
    border: none !important;
  }
  .css-t3ipsp-control {
    border-style: none !important;
  }
  .css-evravd-control {
    border: none !important;
  }
  .css-1e89cqw-control {
    border-style: none !important;
  }
}

.badge {
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -ms-border-radius: 0px !important;
  -o-border-radius: 0px !important;
}

.conversion-button:hover {
  color: black !important;
  background-color: rgb(228, 247, 228) !important;
}

.btn-group-vertical {
  outline: none !important;
  box-shadow: none !important;
}
.dropdown-item:hover {
  background-color: rgb(212, 210, 210) !important;
}
.dropdown-menu {
  padding: 0.5rem !important;
}
.btn-group.show .dropdown-toggle {
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
  padding: 2px !important;
}
.btn-group:focus {
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
  padding: 2px !important;
}

.transaction-card:hover {
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.2)
  ) !important;
}

.list-dropdown {
  .btn {
    padding: 2px !important;
  }
  .btn:focus {
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
  }
}

.simplebar-style-with-pagination {
  height: 72.6vh !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.simplebar-style-view-page {
  height: 82vh !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.input-group-text {
  border-radius: 0px !important;
}

.mobile-view {
  flex-direction: row;
}
.Toastify__toast .Toastify__progress-bar {
  height: 1px !important;
}

.card-style {
  height: 91vh;
}
.cashier-screen-check {
  .form-check-label {
    margin: 0px !important;
    font-size: 10px !important;
    font-family: "Inter", "Source Sans Pro", Helvetica, Arial, sans-serif !important;
  }
}

.customer-contacts-image {
  height: 3rem !important;
  width: 3rem !important;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(188, 238, 188);
}

.customer-info-tab-content {
  h5 {
    font-size: 0.95rem;
  }
  p {
    font-size: 0.8rem;
  }
}
.activity-tree {
  li {
    border-left: 1px solid #00d27a;
    .icon-cover {
      position: relative;
      left: -11px;
      background-color: #fff;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      svg {
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        position: relative;
        left: -1px;
      }
    }
    .card {
      margin-bottom: 1.5rem;
      max-width: 93% !important;
      .small {
        font-size: 0.65rem;
      }
      p {
        font-size: 0.75rem;
      }
    }
    :last-child {
      .card {
        margin-bottom: 0;
      }
    }
  }
}

.dropdown-button-style {
  font-size: 10px !important;
  padding: 5px !important;
  padding-left: 14px !important;
  padding-right: 14px !important;
}
.dropdown-button-print {
  font-size: 12px !important;
  padding: 0.25rem !important;
}

.finance-settings-label {
  .form-label {
    font-weight: 400 !important;
    padding: 6.7px !important;
  }
}

.list-item-letters {
  height: 2rem !important;
  width: 2rem !important;
  background-color: rgb(216, 216, 245);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Georgia, "Times New Roman", Times, serif !important;
  color: black;
}

.profile-image-wrapper {
  height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  .profile-image {
    height: 15vh;
    width: 15vh;
    border-radius: 50% !important;
    -webkit-border-radius: 50% !important;
    -moz-border-radius: 50% !important;
    -ms-border-radius: 50% !important;
    -o-border-radius: 50% !important;
  }
}

.role-style {
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
}

//For display resolution 1680 * 1050
@media screen and (min-width: 1600px) and (max-width: 1680px) {
  .simplebar-style-with-pagination {
    height: 70.8vh !important;
  }
  .simplebar-style-view-page {
    height: 78.8vh !important;
  }
}

//For laptop screen
@media screen and (min-width: 1024px) and (max-width: 1440px) {
  .simplebar-style-with-pagination {
    height: 70.8vh !important;
  }

  input.form-control {
    line-height: 19px !important;
  }
  .css-1s2u09g-control {
    min-height: 27px !important;
    height: 4px !important;
  }
  .css-rb1vna-control {
    min-height: 27px !important;
    height: 4px !important;
  }
  .css-1705uev-control {
    min-height: 27px !important;
    height: 4px !important;
  }
  .css-tlfecz-indicatorContainer {
    padding: 2px !important;
  }
  .css-1gtu0rj-indicatorContainer {
    padding: 2px !important;
  }
  .css-1hb7zxy-indicatorContainer {
    padding: 2px !important;
  }
  .css-1pahdxg-control {
    min-height: 27px !important;
    height: 4px !important;
  }
  .css-1jknvbe-menu {
    font-size: 10px !important;
  }
  .form-select {
    line-height: 15px !important;
  }
  .form-table-layout {
    thead {
      tr {
        th {
          font-size: 10px !important;
        }
      }
    }
  }
  .table-header-size {
    font-size: 11px !important;
    padding: 4px !important;
  }
  .sales-view-page-heading {
    font-size: 17px !important;
  }
}

@media screen and (min-width: 886px) and (max-width: 1024px) {
  .simplebar-style-with-pagination {
    height: 70.5vh !important;
  }
  .sales-view-page-heading {
    font-size: 14px !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 886px) {
  .simplebar-style-with-pagination {
    height: 70.5vh !important;
  }
  .sales-view-page-heading {
    font-size: 10px !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 425px) {
  .simplebar-style {
    .form-wrapper {
      padding-left: 30px !important;
    }
  }
  .simplebar-style-with-pagination {
    height: 70vh !important;
  }
  .mobile-view {
    padding-top: 10px !important;
    padding-bottom: 8px !important;
    flex-direction: column !important;
    align-items: left !important;
  }
  .icon {
    height: 18px;
  }
  .profile-image-wrapper {
    align-items: center;
  }
}

.tile-link-row {
  margin: 0 -7.5px;
}

.tile-link {
  width: 100%;
  max-width: 180px;
  padding: 7.5px;
  transition: all 0.5s ease;
  &:hover {
    transform: scale(1.03);
  }
  .tiles-header {
    background-color: #325068d9 !important;
    padding: 10px !important;
    color: white !important;
    border-radius: 0px !important;
    -webkit-border-radius: 0px !important;
    -moz-border-radius: 0px !important;
    -ms-border-radius: 0px !important;
    -o-border-radius: 0px !important;
    text-overflow: clip !important;
    h6 {
      color: white !important;
    }
  }
}
.tile-link-header {
  .tiles-header {
    background-color: #325068d9 !important;
    padding: 10px !important;
    color: white !important;
    border-radius: 0px !important;
    -webkit-border-radius: 0px !important;
    -moz-border-radius: 0px !important;
    -ms-border-radius: 0px !important;
    -o-border-radius: 0px !important;
    text-overflow: clip !important;
    h6 {
      color: white !important;
      &:hover {
        cursor: pointer !important;
      }
    }
  }
}

.flex-fill-card-color {
  background-color: #eef2f8 !important;
  margin-top: 2rem;
  box-shadow: none;
}
.card-color {
  background-color: #eef2f8 !important;
}

.common-custom-tabs-crm {
  background-color: #e3e3e3;
}

button[id^="common-custom-tabs-crm-tab-"] {
  color: #47484a;
}

button[id^="common-custom-tabs-crm-tab-"]:hover {
  color: #47484a;
}

.common-custom-tabs-crm > .nav-item > .nav-link.active {
  background-color: #8491a3;
  border: none;
  color: #ffffff;
  border-radius: 3px 3px 3px 3px !important;
}
.custom-transperent-common-button:focus {
  box-shadow: none !important;
  border: none !important;
  line-height: 1.5 !important;
  border: 1px solid transparent !important;
}

.custom-transperent-common-button:active {
  border: none !important;
  box-shadow: none !important;
  line-height: 1.5 !important;
  border: 1px solid transparent !important;
}

.custom-transperent-common-button {
  border-color: transparent !important;
}

//common style for tables

.custom-table-tr-crm {
  border: none !important;
  border-color: white !important;
}
.custom-table-head-tr-crm {
  border: none !important;
  border-color: white !important;
}

.custom-image-crm {
  max-width: 50px !important;
  max-height: 60px !important;
}
.custom-table-crm > .custom-table-body-crm > tr:nth-of-type(odd) > * {
  color: inherit !important;
}

.custom-table-crm > .custom-table-body-crm > tr:nth-child(2n + 1) > td,
.table-striped > tbody > tr:nth-child(2n + 1) > th {
  background-color: #e6ebfb !important;
}

.custom-more-button-crm:hover {
  background-color: transparent !important;
  color: #27bcfd !important;
  box-shadow: none !important;
}

.custom-delete-button-crm:hover {
  background-color: transparent !important;
  color: #e63757 !important;
  box-shadow: none !important;
}
.custom-edit-button-crm:hover {
  background-color: transparent !important;
  color: #f5803e !important;
  box-shadow: none !important;
}

.custom-badge-crm {
  border-radius: 0rem 50rem 50rem 0rem !important;
  width: 9vh;
}

.custom-dropdown-button > .btn-transparent:focus {
  box-shadow: none !important;
  border-color: #ffffff !important;
}
.custom-dropdown-button > .btn-transparent:active {
  border-color: #ffffff !important;
}

.custom-dropdown-button > .btn-transparent.show {
  border-color: #ffffff !important;
}
.custom-dropdown-button > .dropdown-menu.show {
  margin-top: 5px !important;
}

.custom-dropdown-button-report-pagination:focus {
  box-shadow: none !important;
}

.custom-module-header {
  color: #060606;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.custom-header-text {
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.custom-table-body-text {
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.custom-table .custom-table-body > tr:nth-of-type(odd) > * {
  background-color: #f9fafd !important;
}

.custom-table-head-th-action {
  width: 7% !important;
}
.custom-table-body-td-action {
  width: 7% !important;
}

// commented for future use
// .custom-table-body-td-div-action {
//   visibility: hidden !important;
// }
.custom-accordion
  > .custom-accordion-item
  > .custom-accordion-header
  > .accordion-button:focus {
  box-shadow: none !important;
}
.custom-accordion
  > .custom-accordion-item
  > .custom-accordion-header
  > .accordion-button:not(.collapsed) {
  color: #5e6e82 !important;
}

.lead-form > .accordion-button {
  background-color: #cdd1d6 !important;
}

.custom-select {
  border-radius: 0px !important;
  .css-evravd-control {
    border-radius: 0px !important;
  }
  .css-1e89cqw-control {
    border-radius: 0px !important;
    border-color: #b3a7ee !important;
    border: none !important;
  }
  .css-1e89cqw-control:hover {
    border-color: #b3a7ee;
  }
  .css-1l6xpo6-control {
    border-radius: 0px !important;
    border-color: #b3a7ee;
  }
  .css-1l6xpo6-control:hover {
    background-color: none !important;
    border-color: #b3a7ee !important;
  }
  .css-evravd-control:hover {
    border-color: #b3a7ee;
  }
  .css-qbdosj-Input {
    margin: 0px !important;
  }
  .css-13cymwt-control:hover {
    border-color: #b3a7ee !important;
  }
  .css-t3ipsp-control {
    border-radius: 0px !important;
    border-color: #b3a7ee !important;
  }
  .css-t3ipsp-control:hover {
    border-color: #b3a7ee !important;
  }
}
.form-select {
  line-height: 19px !important;
}
.form-select:hover {
  border-color: #b3a7ee !important;
}

.form-check-input:checked {
  background-color: #6940e3 !important;
  border-color: #6940e3 !important;
}
// commented for future use
// .custom-table-row:hover {
//   .custom-table-body-td-div-action {
//     visibility: visible !important;
//   }
// }

.preview-image-wrapper > p > img {
  cursor: pointer;
}

.is-invalid-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 75%;
  color: #e63757;
}

.custom-multi-select-checkbox {
  --rmsc-main: #b3a7ee !important;
  --rmsc-hover: #fff !important;
  --rmsc-selected: #eeecfa !important;
  --rmsc-radius: 0px !important;
  --rmsc-h: 31px !important;
}

.ql-snow > .ql-tooltip {
  left: 0px !important;
  top: 0px !important;
}

.custom-common-switch {
  .form-check-input {
    height: 1.8em !important;
    width: 3.5em !important;
    transition: background-position 0.2s ease-in-out !important;
    border-color: #9da9bb !important;
  }
  .form-check-input:checked {
    background-color: #00d27af0 !important;
  }
  .form-check-input:focus {
    box-shadow: none !important;
  }
}
//temporary css
.custom-sprint-tabs > .nav-item > #custom-sprint-tabs-tab-tasklist {
  padding: 0px !important;
}

.custom-button-action {
  border: none !important;
}

.custom-button-action:focus {
  box-shadow: none !important;
}
.custom-button-action:active {
  box-shadow: none !important;
  border: none !important;
}

.custom-button-action:hover {
  .custom-button-edit-icon {
    color: #f15f07 !important;
  }
  .custom-button-convert-icon {
    color: #000000 !important;
  }
  .custom-button-delete-icon {
    color: #ff3232 !important;
  }
  .custom-button-view-icon {
    color: #4b35cf !important;
  }
  .custom-button-status-edit-icon {
    color: #0064e7 !important;
  }
  .custom-button-stage-edit-icon {
    color: #850f8d !important;
  }
}
//custom opportunity overview header tab
.custom-header-tabs > .nav-item {
  flex-grow: 0 !important;
}

.custom-header-tabs > .nav-item > .nav-link {
  color: #1d1d1d !important;
  padding: 0px 12px !important;
  width: fit-content !important;
  font-size: 12px !important;
}
.custom-header-tabs > .nav-item > .nav-link.active {
  color: #4a7de6 !important;
  border-bottom: 2px solid #4a7de6 !important ;
}

//custom lead offcanvas header tab
.custom-lead-offcanvas-header-tabs > .nav-item {
  flex-grow: 0 !important;
}

.custom-lead-offcanvas-header-tabs > .nav-item > .nav-link {
  color: #1d1d1d !important;
  padding: 3px 15px !important;
  width: fit-content !important;
  font-size: 12px !important;
}

.custom-lead-offcanvas-header-tabs > .nav-item > .nav-link.active {
  color: #4a7de6 !important;
  border-bottom: 2px solid #4a7de6 !important ;
}
