.task-custom-button-crm > .dropdown-toggle {
  border-radius: 1rem !important;
  border: 1px solid var(--falcon-border-color) !important;
}

.custom-text-muted {
  color: #6c757d;
}

.custom-text-muted-crm {
  font-size: 0.6944444444rem !important;
  color: #6c757d;
}

.task-overview-card-body-crm {
  background-color: whitesmoke;
}

.task-custom-profile-image-crm {
  max-width: 30px !important;
  max-height: 40px !important;
}

.task-custom-table-header-div-crm {
  min-height: 5.4vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.simplebar-style-in-tab-view {
  overflow: hidden;
  overflow-y: auto;
  overflow-x: auto;
  height: 73vh;
}

.simplebar-style-in-tab-view-without-pagination {
  overflow: hidden;
  overflow-y: auto;
  overflow-x: auto;
  height: 74.6vh;
}

@media only screen and (max-width: 1629px) {
  .task-custom-table-header-div-crm {
    min-height: 6vh;
  }
}

.task-custom-dropdown-item-edit-crm:hover {
  background-color: #ffedbb !important;
}

.task-custom-dropdown-item-delete-crm:hover {
  background-color: #ffb0b0 !important;
}
.task-custom-titile-link:hover {
  text-decoration: none;
}
