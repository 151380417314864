.customer-details-offcanvas .customer-header {
  background-color: #f3f8fe;
  margin: 0 -1rem;
  padding: 15px;
}
.customer-details-offcanvas .customer-header .profile-icon {
  width: 60px;
  height: 60px;
  background-color: #fff;
  border-radius: 50%;
}
.customer-details-offcanvas .customer-info-tab-content h5 {
  font-size: 0.95rem;
}
.customer-details-offcanvas .customer-info-tab-content p {
  font-size: 0.8rem;
}
.customer-details-offcanvas .activity-tree li {
  border-left: 1px solid #00d27a;
}
.customer-details-offcanvas .activity-tree li .icon-cover {
  position: relative;
  left: -11px;
  background-color: #fff;
  width: 25px;
  height: 25px;
  border-radius: 50%;
}
.customer-details-offcanvas .activity-tree li .icon-cover svg {
  transform: scaleX(-1);
  position: relative;
  left: -1px;
}
.customer-details-offcanvas .activity-tree li .card {
  margin-bottom: 1.5rem;
}
.customer-details-offcanvas .activity-tree li .card .small {
  font-size: 0.65rem;
}
.customer-details-offcanvas .activity-tree li .card p {
  font-size: 0.75rem;
}
.customer-details-offcanvas .activity-tree li:last-child .card {
  margin-bottom: 0;
}/*# sourceMappingURL=CustomerDetails.css.map */