.lead-info-style {
  .nav-pills {
    background-color: white !important;
  }
  .nav-pills .nav-link {
    background-color: white !important;
    color: black !important;
  }
  .nav-pills .nav-link.active {
    border-bottom: 2px solid green !important;
    font-weight: 750 !important;
  }
  .nav-pills .nav-link:hover {
    border-bottom: 2px solid green !important;
  }
}

.lead-information-wrapper {
  .lead-information {
    font-size: 14px !important;
    margin: 0px !important;

  }
}
