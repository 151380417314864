.custom-acccordion-reports > .custom-acccordion-item-reports {
  border: none;
  .custom-acccordion-header-reports {
    .accordion-button:not(.collapsed) {
      box-shadow: none;
    }
    .accordion-button:focus {
      box-shadow: none;
    }
  }
}

.custom-title-reports {
  display: inline-block;
  text-decoration: none;
}

.custom-title-reports::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: linear-gradient(to left, #a7afb4 40%, #e2ebf0);
  transition: width 0.4s;
}

.custom-title-link-animation-project-report::after {
  background: linear-gradient(to left, #e63757 40%, #ffffff) !important;
}

.custom-title-link-animation-task-report::after {
  background: linear-gradient(to left, #9a5cfe 40%, #ffffff) !important;
}

.custom-title-link-animation-running-completed-tasks-report::after {
  background: linear-gradient(to left, #3db6d2 40%, #ffffff) !important;
}

.custom-title-link-animation-task-tracker-report::after {
  background: linear-gradient(to left, #5e6f12 40%, #ffffff) !important;
}

.custom-title-link-animation-employee-report::after {
  background: linear-gradient(to left, #27c6fd 40%, #ffffff) !important;
}

.custom-title-link-animation-tickets-report::after {
  background: linear-gradient(to left, #fdb527 40%, #ffffff) !important;
}

.custom-title-link-animation-opportunity-report::after {
  background: linear-gradient(to left, #ff9751 40%, #ffffff) !important;
}

.custom-title-link-animation-lead-conversion-report::after {
  background: linear-gradient(to left, #c0e030 40%, #ffffff) !important;
}

.custom-title-link-animation-lead-source-summery-report::after {
  background: linear-gradient(to left, #F7C600 40%, #ffffff) !important;
}

.custom-title-link-animation-employee-lead-report::after {
  background: linear-gradient(to left, #0fa947 40%, #ffffff) !important;
}

.custom-title-link-animation-employee-task-summary-report::after {
  background: linear-gradient(to left, #da452f 40%, #ffffff) !important;
}

.custom-title-link-animation-sprint-report::after {
  background: linear-gradient(to left, #29bbe7 40%, #ffffff) !important;
}

.custom-title-reports:hover::after {
  width: 100%;
}

.custom-report-view-button:focus {
  box-shadow: 0 0 0 0.2rem #fff !important;
}

.custom-dropdown-button > .btn-transparent {
  height: 100%;
}
