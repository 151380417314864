$Account_Card_Border_Color: #d4d7fb;
$Delete_Hover_Color: #fa545442;
$View_Hover_Color: #9dc2f294;
$Edit_Hover_Color: #f2c1aa9c;

.account-card-header {
  position: relative;
  width: fit-content;
  border-top: 18px solid $Account_Card_Border_Color !important;
  border-bottom: 18px solid $Account_Card_Border_Color !important;
  border-left: 56px solid $Account_Card_Border_Color !important;
  border-right: 18px solid transparent !important;
  box-shadow: -2px 3px 4px -1px rgba(0, 0, 0, 0.2);

  .account-card-header-img {
    position: absolute;
    width: 74px;
    height: 24px;
    top: -10px;
    left: -50px;
    object-fit: contain;
  }
}

.common-grid-card-main-body {
  cursor: pointer;
  &:hover {
    transition: all 0.2s ease-in;
    transform: scale(1.03);
    background-color: white !important;
  }
}

.common-grid-card-body {
  &:hover {
    h1 {
      color: black;
    }
  }
}

.account-card-action-btn {
  border-radius: 6px !important;
  padding: 4px 8px !important;
}

.account-card-delete {
  &:hover {
    background-color: $Delete_Hover_Color !important;
  }
}

.account-card-edit {
  &:hover {
    background-color: $Edit_Hover_Color !important;
  }
}

.account-card-view:hover {
  &:hover {
    background-color: $View_Hover_Color !important;
  }
}
