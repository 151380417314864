.ticket-custom-tabs {
  background-color: #e3e3e3;
}

button[id^="ticket-custom-tabs-tab-"] {
  color: #47484a;
}

button[id^="ticket-custom-tabs-tab-"]:hover {
  color: #47484a;
}

.ticket-custom-tabs > .nav-item > .nav-link.active {
  background-color: #8491a3;
  color: #ffffff;
  border-radius: 3px 3px 3px 3px !important;
}

.ticket-custom-filter > .btn-primary {
  color: #5e6e82 !important;
  background-color: #ffffff !important;
  border-color: #efefef !important;
}

.ticket-custom-add {
  background-color: #ffffff !important;
  border-color: #efefef !important;
}
