.custom-text-editor .ql-container {
  flex: auto;
}

.custom-text-editor .ql-container {
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  background: #fefcfc;
}

/* Snow Theme */
.custom-text-editor .ql-snow.ql-toolbar {
  display: block;
  background: #eaecec;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}
