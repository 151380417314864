
.custom-table-body-crm > tr:nth-of-type(odd) > * {
  color: inherit !important;
}
.campaign-card .custom-table-body-crm > tr:nth-child(2n + 1) > td,
.table-striped > tbody > tr:nth-child(2n + 1) > th {
  background-color: #f4f6fa !important;
}
.custom-table-head-tr-crm {
  border: none !important;
  border-color: white !important;
}

.campaign-card .custom-table-body-crm > tr:nth-child(2n) {
  --falcon-table-striped-bg: #ffffff;
}
.campaign .status-circle {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 8px;
    margin-top: 3px;
  }
  .ellipsis-icon{
    margin-top: 4px;
    background-color: #eaedf8;
    padding: 4px;
    border-radius: 8px;
  }
  .md_mode_edit_icon{
    margin-top: 7px;
    background-color: #eaedf8;
    padding: 4px;
    border-radius: 8px;
  }
  .campaign_progress .progress-bar{
    background-color: #7b59c4 !important;
  }