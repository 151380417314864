.custom-empl-header-tabs > .nav-item > .nav-link {
  color: #1d1d1d !important;
}

.custom-empl-header-tabs > .nav-item > .nav-link.active {
  background-color: #e8effe !important;
  color: #4a7de6 !important;
}

.custom-postioning-header-employee-overview {
  position: absolute;
  background-color: #fff;
  translate: -3px -13px;
}

///profile image section

.profile-pic-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.pic-holder {
  text-align: center;
  position: relative;
  border-radius: 50%;
  width: 130px;
  height: 130px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pic-holder .pic {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}

.pic-holder .upload-file-block,
.pic-holder .upload-loader {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(90, 92, 105, 0.7);
  color: #f8f9fc;
  font-size: 12px;
  font-weight: 600;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
}

.pic-holder .upload-file-block {
  cursor: pointer;
}

.pic-holder:hover .upload-file-block,
.uploadProfileInput:hover ~ .upload-file-block {
  opacity: 1;
}

.pic-holder.uploadInProgress .upload-file-block {
  display: none;
}
