.project-wrapper {
  .nav-pills .nav-link.active {
    background-color: transparent !important;
    border-bottom: 2px solid rgb(90, 44, 110);
    font-weight: bold;
  }
  .nav-link {
    color: black !important;
    font-size: 14px !important;
    padding: 12px;
  }
  .nav-link:hover {
    border-bottom: 2px solid rgb(90, 44, 110);
  }
}

.project-custom-chart-crm {
  background: linear-gradient(to top, #fff 50%, #6690f4 50%);
  height: 15rem;
}
.project-custom-card-body-pi-chart-crm {
  height: 360px !important;
}
.project-custom-activity-card-crm {
  width: -webkit-fill-available;
}

.project-custom-card-crm {
  background-color: transparent !important;
}
.project-custom-card-head-crm {
  background-color: #fff !important;
  border-radius: 0px !important;
}
.project-custom-card-body-crm {
  background-color: #fff !important;
}
.project-custom-nav-link-crm.active {
  background-color: #fff !important;
  border-bottom: #7f8efd 3px solid !important;
  border-radius: 0px !important;
  font-weight: bolder;
}
.project-custom-nav-link-crm {
  color: #5e6e82 !important;
  padding: 10px !important;
  min-width: 15vh !important;
}
.project-custom-nav-link-crm:hover {
  background-color: #fff !important;
  border-bottom: #7f8efd 3px solid !important;
  border-radius: 0px !important;
  font-weight: bolder;
}
