.activity-container-div {
  position: relative;
  padding: 10px 10px 10px 50px;
}
.activity-container-div,
.activity-container-div * {
  box-sizing: border-box;
}

.activity-container-div::before {
  content: "";
  width: 2px;
  background-color: #ff2146;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 12px;
}

.activity-container-wrapper {
  padding: 15px 15px;
  background-color: #e9e9e9;
  position: relative;
  border-radius: 6px;
  margin-bottom: 10px;
  width: 100%;
}

.activity-container-wrapper::before {
  content: "";
  border: 10px solid transparent;
  border-right-color: #e9e9e9;
  border-left: 0;

  position: absolute;
  top: 20%;
  left: -10px;
}

.activity-container-wrapper::after {
  content: "";
  background: #fff;
  border: 4px solid #ff2146;
  width: 14px;
  height: 14px;
  border-radius: 100%;

  position: absolute;
  top: 20%;
  left: -44px;
}

//////////////////////////////// ActivityLog Skelton.jsx CSS ////////////////////////////////////

.activity-container-div-skelton {
  position: relative;
  padding: 10px 10px 10px 50px;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;

  &.visible {
    opacity: 1;
  }
}

.activity-container-div-skelton,
.activity-container-div-skelton * {
  box-sizing: border-box;
}

.activity-container-div-skelton::before {
  content: "";
  width: 2px;
  background-color: #E9E9E9;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 12px;
}

.activity-container-wrapper-skelton {
  padding: 15px 15px;
  background-color: #e9e9e9;
  position: relative;
  border-radius: 6px;
  margin-bottom: 10px;
  width: 100%;
}

.activity-container-wrapper-skelton::before {
  content: "";
  border: 10px solid transparent;
  border-right-color: #e9e9e9;
  border-left: 0;

  position: absolute;
  top: 20%;
  left: -10px;
}

.activity-container-wrapper-skelton::after {
  content: "";
  background: #fff;
  border: 4px solid #E9E9E9;
  width: 14px;
  height: 14px;
  border-radius: 100%;

  position: absolute;
  top: 20%;
  left: -44px;
}

//////////////////////////////// ActivityLog.jsx CSS ////////////////////////////////////

.card-custom-timeline {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
}

.vertical-timeline-custom {
  width: 100%;
  position: relative;
  padding: 1.5rem 0 1rem;
}

.vertical-timeline-custom::before {
  content: "";
  position: absolute;
  top: 0;
  left: 67px;
  height: 100%;
  width: 4px;
  background: #e9ecef;
  border-radius: 0.25rem;
}

.vertical-timeline-element-custom {
  position: relative;
  margin: 0 0 1rem;
}

.vertical-timeline--animate-custom
  .vertical-timeline-element-icon-custom.bounce-in {
  visibility: visible;
  animation: cd-bounce-1 0.8s;
}
.vertical-timeline-element-icon-custom {
  position: absolute;
  top: 0;
  left: 60px;
}

.vertical-timeline-element-icon-custom .badge-dot-xl {
  box-shadow: 0 0 0 5px #fff;
}

.badge-dot-xl-custom {
  width: 18px;
  height: 18px;
  position: relative;
}
// .badge:empty {
//   display: none;
// }

.badge-dot-xl-custom::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 0.25rem;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -5px 0 0 -5px;
  background: #fff;
}

.vertical-timeline-element-content {
  position: relative;
  margin-left: 90px;
  font-size: 0.8rem;
}

.vertical-timeline-element-content .timeline-title {
  font-size: 0.8rem;
  text-transform: uppercase;
  margin: 0 0 0.5rem;
  padding: 2px 0 0;
  font-weight: bold;
}

.vertical-timeline-element-content .vertical-timeline-element-date {
  display: block;
  position: absolute;
  left: -90px;
  top: 0;
  padding-right: 10px;
  text-align: right;
  color: #adb5bd;
  font-size: 0.7619rem;
  white-space: nowrap;
}

.vertical-timeline-element-content:after {
  content: "";
  display: table;
  clear: both;
}
